<template>
<div :key="`key_${key}`">
    <div class="kt-subheader kt-grid__item">
        <div class="kt-container kt-container--fluid">
            <div class="kt-subheader__main">
                <h3 class="kt-subheader__title text-nowrap text-truncate">
                    <SVGIcon
                        hex-color="#0f88ef"
                        class="mt-2 mr-3"
                        name="group"
                    />
                    Student Groups
                </h3>

                <ul
                    v-if="schoolTermMarkingPeriodId"
                    class="nav nav-pills nav-pills-sm nav-sm py-0 px-3 my-0"
                    role="tablist"
                >
                    <small class="mx-3 mt-2">Group Working Marking Period</small>
                    <li
                        v-for="mp in visibleMarkingPeriods"
                        :key="`mp_${mp.schoolTermMarkingPeriodId}`"
                        class="nav-item"
                    >
                        <a
                            class="nav-link"
                            :class="{ active: mp.schoolTermMarkingPeriodId == schoolTermMarkingPeriodId }"
                            href="#"
                            @click.stop.prevent="editGroup({ ...studentGroup, schoolTermMarkingPeriodId: mp.schoolTermMarkingPeriodId })"
                        >
                            {{ mp.markingPeriod }}
                        </a>
                    </li>
                </ul>
            </div>
            <div v-if="formattedGroup" class="kt-subheader__toolbar">
                <div class="kt-subheader__wrapper mt-1">
                    <button
                        v-if="!formattedGroup.deleted && isGroupOwner"
                        type="button"
                        class="btn btn-clean btn-clean-danger btn-bold"
                        @click.stop.prevent="editGroup({ ...studentGroup, deleted: true })"
                    >
                        Delete Group
                    </button>
                    <button
                        v-else-if="formattedGroup.deleted && isGroupOwner"
                        type="button"
                        class="btn btn-clean btn-clean-success btn-bold"
                        @click.stop.prevent="editGroup({ ...studentGroup, deleted: false })"
                    >
                        Restore Group
                    </button>
                    <button
                        v-if="!formattedGroup.deleted && !isGroupOwner"
                        type="button"
                        class="btn btn-clean btn-clean-warning btn-bold"
                        @click.stop.prevent="staffChanged({ schoolStaffId, canEditGroup: formattedGroup.canEditGroup, deleted: true })"
                    >
                        Leave Group
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid">
        <div v-if="!formattedGroup">
            No group found
        </div>
        <div v-else>
            <div class="row">
                <div class="col-md-12 col-lg-7 col-xl-8">
                    <div class="kt-portlet kt-portlet--mobile">
                        <div class="kt-portlet__head">
                            <div class="kt-portlet__head-label">
                                <h3 class="kt-portlet__head-title">
                                    Group Info <small>{{ formattedGroup.groupName }} </small>
                                </h3>
                            </div>
                            <div class="kt-portlet__head-toolbar">
                                <div class="kt-portlet__head-actions">
                                    <button
                                        class="btn btn-clean btn-clean-primary btn-sm btn-bold"
                                        @click.stop.prevent="saveGroupInfo"
                                    >
                                        Save Info
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="kt-portlet__body">
                            <div class="row row-no-padding row-col-separator-xl">
                                <div class="col-lg-8">
                                    <v-md-editor
                                        v-if="canEditGroup"
                                        v-model="settings.groupDescription"
                                        height="220px"
                                        :right-toolbar="rightToolbar"
                                        :left-toolbar="leftToolbar"
                                        mode="edit"
                                        class="v-md-editor-markdown"
                                    />
                                    <span v-else>
                                        {{ formattedGroup.groupDescription }}
                                    </span>
                                </div>
                                <div class="col-4 px-4">
                                    <div>
                                        <label>Group Name</label>
                                        <input
                                            v-model="settings.groupName"
                                            type="text"
                                            class="form-control"
                                            maxlength="255"
                                            placeholder=""
                                        >
                                    </div>
                                    <div class="mt-3">
                                        <label class="w-100">Group Color</label>
                                        <div class="input-group">
                                            <ColorSwatch
                                                class="input-group-prepend"
                                                :color-change="selectedGroupColor"
                                                :color-selected="settings.groupColor || 'White'"
                                                :item-index="0"
                                            />
                                            <input
                                                type="text"
                                                class="form-control"
                                                disabled="disabled"
                                                :value="settings.groupColor"
                                            >
                                        </div>
                                    </div>
                                    <div class="mt-3">
                                        <label class=" w-100">Group Icon</label>
                                        <div class="input-group kt-input-icon">
                                            <EmojiPicker
                                                :initial-emoji="settings.groupIcon"
                                                :on-selected="emojiPicked"
                                                class="input-group-prepend"
                                            />
                                            <input
                                                type="text"
                                                class="form-control pr-5"
                                                readonly="readonly"
                                                :value="settings.groupIcon"
                                            >
                                            <span
                                                v-if="settings.groupIcon"
                                                class="kt-input-icon__icon kt-input-icon__icon--right"
                                                @click.stop.prevent="clearIcon"
                                            >
                                                <span><i class="la la-close" /></span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 col-lg-5 col-xl-4">
                    <div
                        class="kt-portlet kt-portlet--skin-solid"
                        :class="{'kt-portlet--solid-success': groupType === 'auto', 'kt-portlet--solid-primary': groupType === 'manual'}"
                    >
                        <div class="kt-portlet__head kt-portlet__head--noborder">
                            <div class="kt-portlet__head-label">
                                <h3 class="kt-portlet__head-title kt-font-bolder text-capitalize">
                                    {{ groupType }} Mode
                                </h3>
                            </div>
                            <div v-if="canEditGroup" class="kt-portlet__head-toolbar">
                                <button
                                    class="btn btn-outline-light btn-sm btn-hover-light btn-bold"
                                    @click.stop.prevent="editGroup({ ...studentGroup, groupType: groupType === 'auto' ? 'manual' : 'auto' })"
                                >
                                    Switch Mode
                                </button>
                            </div>
                        </div>
                        <div class="kt-portlet__body kt-margin-t-0 kt-padding-t-0">
                            <div class="kt-font-bolder">
                                <span v-if="groupType === 'manual'">
                                    Students in a Manual group are only added or removed to this group when you manually refresh the group.
                                </span>
                                <span v-else>
                                    Students in an Auto group are automatically added and removed based on the rules you set.
                                    These group changes occur whenever specific student portfolios are changed.
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="kt-portlet kt-portlet--mobile">
                        <div class="kt-portlet__head">
                            <div class="kt-portlet__head-label">
                                <h3 class="kt-portlet__head-title">
                                    Group Staff
                                </h3>
                            </div>
                            <div class="kt-portlet__head-toolbar">
                                <div class="kt-portlet__head-actions">
                                    <button
                                        v-if="!addingStaff"
                                        type="button"
                                        class="btn btn-clean btn-sm btn-icon btn-icon-md"
                                        @click.stop.prevent="addingStaff = !addingStaff"
                                    >
                                        <i class="fa fa-plus" />
                                    </button>
                                    <button
                                        v-else
                                        type="button"
                                        class="btn btn-clean btn-clean-danger btn-sm btn-bold"
                                        @click.stop.prevent="addingStaff = !addingStaff"
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="kt-portlet__body">
                            <div class="kt-widget4">
                                <div
                                    v-for="(staff, idx) in formattedGroup.staff"
                                    :key="`groupStaff_${staff.schoolStaffId}_${idx}`"
                                    class="kt-widget4__item"
                                >
                                    <div class="kt-widget4__pic kt-widget4__pic--pic">
                                        <span
                                            v-if="isGroupOwner && schoolStaffId != staff.schoolStaffId"
                                            class="btn btn-clean btn-clean-danger btn-sm btn-icon btn-icon-md remove-staff"
                                            style="max-width: 40px; width: 40px; height: 40px;"
                                            @click.stop.prevent="staffChanged({ schoolStaffId: staff.schoolStaffId, canEditGroup: staff.canEditGroup, deleted: true })"
                                        >
                                            <i class="la la-close" />
                                        </span>
                                        <UserAvatar
                                            :avatar-user="staff.teacher"
                                            :class="{'staff-image': isGroupOwner && schoolStaffId != staff.schoolStaffId}"
                                            style="max-width: 40px; width: 40px; height: 40px;"
                                        >
                                            <div
                                                class="kt-badge kt-badge--lg kt-badge--success"
                                                style="max-width: 40px; width: 40px; height: 40px;"
                                            >
                                                {{ staff.teacher ? `${staff.teacher.lastName.substring(0,2)}` : '-' }}
                                            </div>
                                        </UserAvatar>
                                    </div>
                                    <div class="kt-widget4__info">
                                        <router-link
                                            v-if="staff.teacher"
                                            :to="{ name: 'TeacherInfo', params: { schoolEmail: staff.teacher.schoolEmail } }"
                                            class="kt-widget4__username"
                                        >
                                            {{ `${staff.teacher.firstName} ${staff.teacher.lastName}` }}
                                        </router-link>
                                        <a
                                            v-else
                                            href="#"
                                            class="kt-widget4__username"
                                        >
                                            Unknown Staff
                                        </a>
                                        <p class="kt-widget4__text">
                                            {{ staff.teacher ? staff.teacher.jobTitle : '' }}
                                        </p>
                                    </div>
                                    <span
                                        v-if="formattedGroup.schoolStaffId == staff.schoolStaffId"
                                        class="btn btn-sm btn-label-success btn-bold"
                                    >
                                        Owner
                                    </span>
                                    <span v-else>
                                        <button
                                            v-if="isGroupOwner"
                                            type="button"
                                            class="btn btn-sm btn-bold"
                                            :class="staff.canEditGroup ? 'btn-label-warning' : 'btn-label-primary'"
                                            @click.stop.prevent="staffChanged({ schoolStaffId: staff.schoolStaffId, canEditGroup: !staff.canEditGroup, deleted: false })"
                                        >
                                            {{ staff.canEditGroup ? 'Editor' : 'Viewer' }}
                                        </button>
                                        <span
                                            v-else
                                            class="btn btn-sm btn-bold"
                                            :class="staff.canEditGroup ? 'btn-label-warning' : 'btn-label-primary'"
                                        >
                                            {{ staff.canEditGroup ? 'Editor' : 'Viewer' }}
                                        </span>
                                    </span>
                                </div>
                                <div
                                    v-if="addingStaff"
                                    class="kt-widget4__item"
                                >
                                    <div class="kt-widget4__info">
                                        <SearchRecipients
                                            :on-selected="staffSelected"
                                            :school-staff-id="schoolStaffId"
                                            :allow-school-wide="false"
                                            :allow-my-students="false"
                                            :show-courses="false"
                                            :show-home-rooms="false"
                                            :show-grade-levels="false"
                                            :show-student-groups="false"
                                            :show-students="false"
                                            :show-guardians="false"
                                            :placeholder="'Search for staff'"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div class="kt-portlet kt-portlet--mobile">
                    <div class="kt-portlet__head">
                        <div class="kt-portlet__head-label">
                            <h3 class="kt-portlet__head-title">
                                Group Students
                            </h3>
                        </div>
                        <div class="kt-portlet__head-toolbar">
                            <div class="kt-portlet__head-actions">
                                <button
                                    v-if="editMode"
                                    type="button"
                                    class="btn btn-warning btn-sm btn-bold mr-2"
                                    :class="{ 'kt-spinner kt-spinner--sm': saving }"
                                    :disabled="saving"
                                    @click.stop.prevent="saveRules"
                                >
                                    Save Rules
                                </button>
                                <button
                                    v-if="formattedGroup.groupRules.length"
                                    type="button"
                                    class="btn btn-success btn-sm btn-bold mr-2"
                                    :class="{ 'kt-spinner kt-spinner--sm kt-spinner--light': saving }"
                                    :disabled="saving"
                                    @click.stop.prevent="refreshGroupStudents"
                                >
                                    Refresh Students
                                </button>
                                <button
                                    v-if="canEditGroup"
                                    type="button"
                                    class="btn btn-sm btn-bold"
                                    :class="editMode ? 'btn-primary' : 'btn-clean btn-clean-primary'"
                                    @click.stop.prevent="editMode = !editMode"
                                >
                                    Edit Students
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="kt-portlet__body">
                        <b-collapse
                            v-model="editMode"
                            class=""
                        >
                            <div class="col-4 mb-5">
                                <SearchRecipients
                                    :on-selected="addMember"
                                    :school-staff-id="schoolStaffId"
                                    :allow-school-wide="false"
                                    :allow-my-students="false"
                                    :show-courses="true"
                                    :show-home-rooms="true"
                                    :show-grade-levels="true"
                                    :show-student-groups="false"
                                    :show-students="true"
                                    :show-guardians="false"
                                    :show-teachers="false"
                                    :placeholder="'Individual students, courses, homeroom, etc.'"
                                />
                            </div>
                            <div class="ml-2 mb-3">
                                <small v-if="groupRules.groupings.length || groupRules.students.length">Group Rules</small>
                            </div>
                            <div class="kt-widget2">
                                <div
                                    v-for="(grouping, idx) in groupRules.groupings"
                                    :key="`new_grouping_${idx}`"
                                    class="kt-widget2__item"
                                    :class="{
                                        'kt-widget2__item--success': grouping.group.courseSectionId,
                                        'kt-widget2__item--primary': grouping.group.homeRoom,
                                        'kt-widget2__item--warning': grouping.group.gradeLevel,
                                    }"
                                >
                                    <div class="kt-widget2__checkbox">
                                        <span
                                            class="btn btn-clean btn-clean-danger btn-sm btn-icon btn-icon-md remove-member mb-3"
                                            style="max-width: 35px; width: 35px; height: 35px;"
                                            @click.stop.prevent="removeMember('grouping', idx)"
                                        >
                                            <i class="la la-close" />
                                        </span>
                                        <div class="kt-badge kt-badge--lg kt-badge--success member-image mb-3">
                                            {{ `${grouping.group ? grouping.group.name : '-'}`.substring(0, 2) }}
                                        </div>
                                    </div>
                                    <div class="kt-widget2__info mt-2" style="width: 27%; max-width: 27%;">
                                        <a
                                            href="#"
                                            class="kt-widget2__title kt-font-lg"
                                            @click.stop.prevent="removeMember('grouping', idx)"
                                        >
                                            {{ grouping.group ? grouping.group.name : 'Unknown Grouping' }}
                                        </a>
                                        <p class="kt-widget2__username">
                                            {{ grouping.group ? grouping.group.extCourseSectionId || grouping.group.subName || '' : '' }}
                                        </p>
                                    </div>
                                    <div
                                        class="kt-widget2__actions"
                                        style="width: 65%; max-width: 65%; text-align: left;"
                                    >
                                        <div
                                            v-for="(filter, index) in grouping.filters"
                                            :key="`new_grouping_${idx}_filter_${index}`"
                                            class="pb-2"
                                        >
                                            <button
                                                type="button"
                                                class="btn btn-clean btn-clean-danger btn-icon btn-sm"
                                                @click.stop.prevent="removeGroupFilter(idx, index)"
                                            >
                                                <i class="la la-close" />
                                            </button>

                                            <span v-if="filter.filter ==='coursesWithAverage'" class="pl-2">
                                                <input
                                                    v-model="filter.filterValue"
                                                    type="number"
                                                    class="form-control col-2"
                                                    style="display: inline;"
                                                >
                                            </span>
                                            <span class="pl-2 pr-2">
                                                <select
                                                    v-model="filter.filter"
                                                    class="form-control"
                                                    :class="{'col-5': filter.filter !== 'coursesWithAverage', 'col-3': filter.filter === 'coursesWithAverage'}"
                                                    style="display: inline;"
                                                >
                                                    <option v-if="grouping.group.courseSectionId" value="average">Average</option>
                                                    <option value="overallAverage">Overall Average</option>
                                                    <option value="attendance">Attendance YTD</option>
                                                    <option value="reportCardGrade">Report Card Grade</option>
                                                    <option value="reportCardAverage">Overall Report Card Average</option>
                                                    <option value="coursesWithAverage">Courses with average</option>
                                                </select>
                                            </span>
                                            <span class="pl-2 pr-2">
                                                <select
                                                    v-model="filter.relation"
                                                    class="form-control col-2"
                                                    style="display: inline;"
                                                >
                                                    <option>=</option>
                                                    <option>{{ '>' }}</option>
                                                    <option>{{ '>=' }}</option>
                                                    <option>{{ '<' }}</option>
                                                    <option>{{ '<=' }}</option>
                                                </select>
                                            </span>
                                            <span class="pl-2">
                                                <input
                                                    v-model="filter.relationValue"
                                                    type="number"
                                                    class="form-control col-2"
                                                    style="display: inline;"
                                                >
                                                %
                                            </span>
                                        </div>
                                        <div>
                                            <button
                                                type="button"
                                                class="btn btn-label-primary btn-icon btn-sm"
                                                @click.stop.prevent="addGroupFilter(idx)"
                                            >
                                                <i class="la la-plus" />
                                            </button>
                                            <span class="kt-font-md kt-font-bold ml-2">
                                                Add Student Filter
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    v-for="(student, idx) in groupRules.students"
                                    :key="`new_student_${idx}`"
                                    class="kt-widget2__item kt-widget2__item--warning"
                                >
                                    <div class="kt-widget2__checkbox">
                                        <span
                                            class="btn btn-clean btn-clean-danger btn-sm btn-icon btn-icon-md remove-member mb-3"
                                            style="max-width: 35px; width: 35px; height: 35px;"
                                            @click.stop.prevent="removeMember('student', idx)"
                                        >
                                            <i class="la la-close" />
                                        </span>
                                        <UserAvatar
                                            :avatar-user="student.student"
                                            class="member-image mb-3"
                                            style="max-width: 35px; width: 35px; height: 35px;"
                                        >
                                            <div
                                                class="kt-badge kt-badge--lg kt-badge--success"
                                                style="max-width: 35px; width: 35px; height: 35px;"
                                            >
                                                {{ `${student.student ? student.student.name : '-'}`.substring(0, 2) }}
                                            </div>
                                        </UserAvatar>
                                    </div>
                                    <div class="kt-widget2__info mt-2" style="width: 27%; max-width: 27%;">
                                        <a
                                            href="#"
                                            class="kt-widget2__title kt-font-lg"
                                            @click.stop.prevent="removeMember('student', idx)"
                                        >
                                            {{ student.student ? student.student.name : 'Unknown student' }}
                                        </a>
                                        <p class="kt-widget2__username">
                                            Student
                                        </p>
                                    </div>
                                    <div
                                        class="kt-widget2__actions"
                                        style="width: 65%; max-width: 65%; text-align: left;"
                                    >
                                        <div class="kt-checkbox-list">
                                            <label class="kt-checkbox kt-checkbox--tick kt-checkbox--success">
                                                <input v-model="student.isRule" type="checkbox"> Save as a rule to always add this individual student when refreshing group
                                                <span />
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </b-collapse>

                        <div :style="`margin-top: ${editMode ? '8%' : ''}`">
                            <div class="kt-widget31">
                                <div
                                    v-for="(student, idx) in formattedGroup.students"
                                    :key="`groupStudent_${student.studentEnrollmentId}_${idx}`"
                                    class="kt-widget31__item"
                                >
                                    <div class="kt-widget31__content">
                                        <div class="kt-widget31__pic">
                                            <span
                                                class="btn btn-clean btn-clean-danger btn-sm btn-icon btn-icon-md remove-member"
                                                style="max-width: 40px; width: 40px; height: 40px;"
                                                @click.stop.prevent="studentChanged(student.studentEnrollmentId, true)"
                                            >
                                                <i class="la la-close" />
                                            </span>
                                            <UserAvatar
                                                :avatar-user="student.student"
                                                class="member-image"
                                                style="max-width: 40px; width: 40px; height: 40px;"
                                            >
                                                <div
                                                    class="kt-badge kt-badge--lg kt-badge--success"
                                                    style="max-width: 40px; width: 40px; height: 40px;"
                                                >
                                                    {{ `${student.student ? student.student.name : '-'}`.substring(0, 2) }}
                                                </div>
                                            </UserAvatar>
                                        </div>
                                        <div class="kt-widget31__info">
                                            <router-link
                                                v-if="student.student"
                                                :to="{name: 'StudentInfo', params: {studentEnrollmentId: student.studentEnrollmentId}}"
                                                class="kt-widget31__username"
                                            >
                                                {{ `${student.student.firstName} ${student.student.lastName}` }}
                                            </router-link>
                                            <a
                                                v-else
                                                href="#"
                                                class="kt-widget31__username"
                                            >
                                                Unknown Student
                                            </a>
                                            <p class="kt-widget31__text">
                                                {{ student.student
                                                    ? `${student.student.homeRoom ? `HR: ${student.student.homeRoom}` : ''} ${student.student.gradeLevel ? `GL: ${student.student.gradeLevel}` : ''}`
                                                    : '' }}
                                            </p>
                                        </div>
                                    </div>
                                    <div class="kt-widget31__content" style="width: 70%;">
                                        <div class="kt-widget31__progress">
                                            <div v-if="student.aggregates" class="row">
                                                <small class="align-self-center mr-3">Report Card Average</small>
                                                <div
                                                    v-for="(avg, index) in [(student.aggregates.reportCardAverages || []).find((a) => a.schoolTermMarkingPeriodId == schoolTermMarkingPeriodId) || {}]"
                                                    :key="`reportCardAverage_${avg.studentEnrollmentId}_${avg.schoolTermMarkingPeriodId}_${index}`"
                                                >
                                                    <span
                                                        v-if="avg.reportCardAverage"
                                                        class="btn btn-md btn-icon btn-primary mr-1"
                                                    >
                                                        <span class="course-icon align-items-center d-flex">
                                                            {{ Math.round(avg.reportCardAverage) }}
                                                        </span>
                                                    </span>
                                                    <span
                                                        v-else
                                                        class="btn btn-md btn-icon btn-primary mr-1"
                                                        :disabled="avg.schoolTermMarkingPeriodId != schoolTermMarkingPeriodId"
                                                    >
                                                        <span class="course-icon align-items-center d-flex">
                                                            -
                                                        </span>
                                                    </span>
                                                </div>

                                                <small class="align-self-center mr-3 ml-4">Overall Averages</small>
                                                <div
                                                    v-for="(avg, index) in [...(student.aggregates.progressAverages.filter((a) => a.schoolTermId == schoolTermId) || [])]"
                                                    :key="`progressAverage_${avg.studentEnrollmentId}_${avg.schoolTermMarkingPeriodId}_${index}`"
                                                >
                                                    <button
                                                        v-if="avg.progressAverage"
                                                        class="btn btn-md btn-icon btn-primary mr-1"
                                                        :disabled="avg.schoolTermMarkingPeriodId != schoolTermMarkingPeriodId"
                                                    >
                                                        <span class="course-icon align-items-center d-flex">
                                                            {{ Math.round(avg.progressAverage * 100) }}
                                                        </span>
                                                    </button>
                                                    <button
                                                        v-else
                                                        class="btn btn-md btn-icon btn-primary mr-1"
                                                        :disabled="avg.schoolTermMarkingPeriodId != schoolTermMarkingPeriodId"
                                                    >
                                                        <span class="course-icon align-items-center d-flex">
                                                            -
                                                        </span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>

                                        <span
                                            class="btn-label-brand btn btn-sm btn-bold"
                                            :class="{'Blue': student.generatedFrom === 'Individual',
                                                     'Orange': student.generatedFrom !== 'Individual'}"
                                        >{{ student.generatedFrom }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import { mapState } from 'vuex';
import async from 'async';
import UserAvatar from '../components/UserAvatar.vue';
import SearchRecipients from '../components/SearchRecipients.vue';
import ColorSwatch from '../components/ColorSwatch.vue';
import EmojiPicker from '../components/EmojiPicker.vue';
import courseMixins from '../store/mixins/courseMixins';
import teacherMixins from '../store/mixins/teacherMixins';
import studentMixins from '../store/mixins/studentMixins';
import Types from '../store/Types';
import * as network from '../network';

export default {
    name: 'StudentGroup',
    components: {
        UserAvatar,
        SearchRecipients,
        ColorSwatch,
        EmojiPicker,
    },
    mixins: [courseMixins, teacherMixins, studentMixins],
    data() {
        return {
            key: 0,
            saving: false,
            addingStaff: false,
            editMode: false,
            settings: {
                groupDescription: '',
                groupName: null,
                groupColor: null,
                groupIcon: null,
            },
            groupRules: {
                groupings: [],
                students: [],
            },
            leftToolbar: 'h bold italic strikethrough quote | ul ol table hr link',
            rightToolbar: 'preview',
        };
    },
    computed: {
        ...mapState({
            user: (state) => state.user,
        }),
        schoolTermId() {
            const { user } = this;
            const { school } = user;
            const { schoolTermId } = school;
            return schoolTermId;
        },
        schoolStaffId() {
            const { user } = this;
            const { school } = user;
            const { schoolStaffId } = school;
            return schoolStaffId;
        },
        courses() {
            return this.$_courseMixins_getCourses();
        },
        teachers() {
            return this.$_teacherMixins_getTeachers();
        },
        students() {
            return this.$_studentMixins_getStudents();
        },
        studentAggregates() {
            return this.$store.state.database.studentAggregates;
        },
        studentGroup() {
            const { $route } = this;
            const { studentGroupId } = $route.params;
            return this.$store.state.database.studentGroups.find((g) => g.studentGroupId == studentGroupId) || null;
        },
        formattedGroup() {
            const {
                studentGroup, teachers, students, studentAggregates,
            } = this;
            if (!studentGroup) return null;

            return {
                ...studentGroup,
                staff: studentGroup.staff.map((s) => {
                    const teacher = teachers.find((t) => t.schoolStaffId == s.schoolStaffId) || null;
                    return {
                        ...s,
                        teacher,
                    };
                }).reduce((acc, s) => {
                    if (studentGroup.schoolStaffId == s.schoolStaffId) return [s, ...acc];
                    return [...acc, s];
                }, []),
                students: studentGroup.students.map((s) => {
                    const student = students.find((x) => x.studentEnrollmentId == s.studentEnrollmentId) || null;
                    const aggregates = student ? studentAggregates.find((a) => a.studentId == student.studentId) || null : null;
                    return {
                        ...s,
                        student,
                        aggregates,
                    };
                }).sort((a, b) => {
                    if (!a.student && !b.student) return 0;
                    if (!a.student) return 1;
                    if (!b.student) return -1;
                    return `${a.student.lastName}${a.student.firstName}` > `${b.student.lastName}${b.student.firstName}` ? 1 : -1;
                }),
            };
        },
        markingPeriods() {
            return this.$store.state.database.markingPeriods
                .filter((x) => !x.deleted)
                .map((x) => ({ ...x }));
        },
        schoolTermMarkingPeriodId() {
            const { formattedGroup } = this;
            return formattedGroup ? formattedGroup.schoolTermMarkingPeriodId : null;
        },
        visibleMarkingPeriods() {
            const { markingPeriods, schoolTermMarkingPeriodId } = this;
            const visibleMarkingPeriods = [];
            if (!schoolTermMarkingPeriodId) return markingPeriods.slice(0, 3);

            const selectedIndex = markingPeriods.findIndex((mp) => mp.schoolTermMarkingPeriodId === schoolTermMarkingPeriodId);
            if (selectedIndex === -1) return markingPeriods.slice(0, 3);

            visibleMarkingPeriods.push({ ...markingPeriods[selectedIndex] });

            if (selectedIndex + 1 < markingPeriods.length) {
                visibleMarkingPeriods.push({ ...markingPeriods[selectedIndex + 1] });
            }
            if (selectedIndex - 1 >= 0 && visibleMarkingPeriods.length < 3) {
                visibleMarkingPeriods.unshift({ ...markingPeriods[selectedIndex - 1] });
            }

            if (visibleMarkingPeriods.length < 3) {
                if (selectedIndex + 2 < markingPeriods.length) {
                    visibleMarkingPeriods.push({ ...markingPeriods[selectedIndex + 2] });
                }
                if (selectedIndex - 2 >= 0 && visibleMarkingPeriods.length < 3) {
                    visibleMarkingPeriods.unshift({ ...markingPeriods[selectedIndex - 2] });
                }
            }

            return visibleMarkingPeriods;
        },
        groupType() {
            const { formattedGroup } = this;
            return formattedGroup ? formattedGroup.groupType : null;
        },
        isGroupOwner() {
            const { formattedGroup } = this;
            return formattedGroup ? formattedGroup.isGroupOwner : false;
        },
        canEditGroup() {
            const { formattedGroup } = this;
            return formattedGroup ? formattedGroup.canEditGroup : false;
        },
    },
    watch: {
        studentGroup: {
            handler() {
                this.key += 1;
                this.populateGroupRules();
            },
            deep: true,
        },
    },
    mounted() {
        const { studentGroup } = this;
        if (!studentGroup) return;

        const {
            groupDescription, groupName, groupColor, groupIcon,
        } = studentGroup;

        this.settings = {
            groupDescription,
            groupName,
            groupColor,
            groupIcon,
        };
        this.populateGroupRules();
    },
    methods: {
        populateGroupRules() {
            const { studentGroup, courses, students } = this;
            if (!studentGroup) return;

            const groupings = studentGroup.groupRules.filter((g) => !g.studentEnrollmentId).reduce((acc, group) => {
                const {
                    courseSectionId, homeRoom, gradeLevel, filter, filterValue, relation, relationValue,
                } = group;

                const hasFilter = filter && relation && relationValue;
                if (courseSectionId) {
                    const index = acc.findIndex((x) => x.group && x.group.courseSectionId == courseSectionId);
                    if (index === -1) {
                        acc.push({
                            group: { courseSectionId, ...(courses.find((x) => x.courseSectionId == courseSectionId) || {}) },
                            filters: hasFilter ? [{
                                filter, filterValue, relation, relationValue,
                            }] : [],
                        });
                    } else if (hasFilter) {
                        acc[index].filters.push({
                            filter, filterValue, relation, relationValue,
                        });
                    }
                } else if (homeRoom) {
                    const index = acc.findIndex((x) => x.group && x.group.homeRoom == homeRoom);
                    if (index === -1) {
                        acc.push({
                            group: { homeRoom, name: `Homeroom ${homeRoom}` },
                            filters: hasFilter ? [{
                                filter, filterValue, relation, relationValue,
                            }] : [],
                        });
                    } else if (hasFilter) {
                        acc[index].filters.push({
                            filter, filterValue, relation, relationValue,
                        });
                    }
                } else if (gradeLevel) {
                    const index = acc.findIndex((x) => x.group && x.group.gradeLevel == gradeLevel);
                    if (index === -1) {
                        acc.push({
                            group: { gradeLevel, name: `Grade ${gradeLevel}` },
                            filters: hasFilter ? [{
                                filter, filterValue, relation, relationValue,
                            }] : [],
                        });
                    } else if (hasFilter) {
                        acc[index].filters.push({
                            filter, filterValue, relation, relationValue,
                        });
                    }
                }

                return acc;
            }, []);

            const ruleStudents = studentGroup.groupRules.filter((g) => g.studentEnrollmentId)
                .map((s) => ({ student: students.find((x) => x.studentEnrollmentId == s.studentEnrollmentId) || {}, isRule: s.isRule }));

            this.groupRules = { groupings, students: ruleStudents };
        },
        saveRules() {
            const v = this;
            const {
                user, studentGroup, groupRules, canEditGroup,
            } = v;
            if (v.saving || !studentGroup || !canEditGroup) return;
            v.saving = true;

            const { school } = user;
            const { schoolId, schoolTermId, schoolStaffId } = school;
            const { studentGroupId } = studentGroup;

            const params = {
                url: {
                    schoolId,
                    schoolTermId,
                    schoolStaffId,
                    studentGroupId,
                },
                body: {
                    group: {
                        groupings: groupRules.groupings.map((g) => ({
                            group: g.group,
                            filters: g.filters.filter((f) => f.filter && f.relation && f.relationValue),
                        })),
                        students: groupRules.students.map((s) => ({ studentEnrollmentId: s.student.studentEnrollmentId, isRule: s.isRule })),
                    },
                },
            };

            network.studentGroups.saveGroupRules(params, (err, res) => {
                v.saving = false;
                if (err) return v.showError(err);

                const { rules, newStudents } = res;
                const unique = new Map();
                [...studentGroup.students, ...newStudents].forEach((s) => {
                    unique.set(s.studentEnrollmentId, s);
                });

                v.$store.commit(Types.mutations.SET_DB_STUDENT_GROUPS, [{ ...studentGroup, groupRules: rules, students: [...unique.values()] }]);
            });
        },
        refreshGroupStudents() {
            const v = this;
            const { user, studentGroup } = v;
            if (v.saving || !studentGroup) return;
            v.saving = true;

            const { school } = user;
            const { schoolId, schoolTermId, schoolStaffId } = school;
            const { studentGroupId } = studentGroup;

            const params = {
                url: {
                    schoolId,
                    schoolTermId,
                    schoolStaffId,
                    studentGroupId,
                },
            };

            network.studentGroups.refreshGroupStudents(params, (err, res) => {
                v.saving = false;
                if (err) return v.showError(err);
                const { newStudents } = res;
                v.$store.commit(Types.mutations.SET_DB_STUDENT_GROUPS, [{ ...studentGroup, students: newStudents }]);
            });
        },
        addMember(member) {
            const { isGrouping, studentEnrollmentId, groupingId } = member;
            if (studentEnrollmentId) {
                const { students } = this.groupRules;
                const found = students.find((u) => u.student.studentEnrollmentId == studentEnrollmentId) || null;

                if (!found) students.push({ student: member, isRule: false });
            } else if (isGrouping) {
                const { groupings } = this.groupRules;
                const found = groupings.find((g) => g.group && g.group.groupingId == groupingId) || null;
                if (!found) {
                    groupings.push({
                        group: member, filters: [],
                    });
                }
            }
        },
        removeMember(type, idx) {
            if (type === 'grouping') {
                this.groupRules.groupings = this.groupRules.groupings.filter((g, i) => i != idx);
            } else if (type === 'student') {
                this.groupRules.students = this.groupRules.students.filter((s, i) => i != idx);
            }
        },
        addGroupFilter(idx) {
            const { groupings } = this.groupRules;
            const grouping = groupings[idx];
            grouping.filters.push({
                filter: '',
                filterValue: null,
                relation: '>',
                relationValue: '',
            });
        },
        removeGroupFilter(groupIdx, filterIdx) {
            const { groupings } = this.groupRules;
            const grouping = groupings[groupIdx];
            grouping.filters = grouping.filters.filter((f, i) => i != filterIdx);
        },
        selectedGroupColor(color) {
            this.settings.groupColor = color;
        },
        emojiPicked(emoji) {
            if (!emoji || !emoji.colons) {
                this.settings.groupIcon = null;
                return;
            }
            this.settings.groupIcon = emoji.native;
        },
        staffSelected(staff) {
            const { schoolStaffId } = staff;
            if (this.schoolStaffId == schoolStaffId) return;
            this.staffChanged({ schoolStaffId, canEditGroup: null, deleted: false });
        },
        staffChanged(staff) {
            const v = this;
            const { studentGroup, user } = v;
            if (v.saving || !studentGroup) return;
            v.saving = true;

            const { school } = user;
            const { schoolId, schoolTermId, schoolStaffId } = school;
            const { studentGroupId, groupName } = studentGroup;
            const { canEditGroup, deleted } = staff;

            const params = {
                url: { schoolId, schoolTermId, schoolStaffId },
                body: {
                    staff: [{
                        studentGroupId,
                        schoolStaffId: staff.schoolStaffId,
                        canEditGroup,
                        groupName,
                        groupIcon: null,
                        groupColor: null,
                        groupIndex: null,
                        deleted,
                    }],
                },
            };

            network.studentGroups.saveGroupStaff(params, (err, res) => {
                v.saving = false;
                if (err) return v.showError(err);
                const groupStaff = deleted ? studentGroup.staff.filter((s) => s.schoolStaffId != staff.schoolStaffId)
                    : [...studentGroup.staff, ...res.staff];
                const unique = new Map();
                groupStaff.forEach((s) => {
                    unique.set(s.schoolStaffId, s);
                });
                v.$store.commit(Types.mutations.SET_DB_STUDENT_GROUPS, [{ ...studentGroup, staff: [...unique.values()] }]);
            });
        },
        studentChanged(studentEnrollmentId, deleted) {
            const v = this;
            if (v.saving || !studentEnrollmentId) return;
            v.saving = true;

            const { studentGroup, user } = v;
            const { school } = user;
            const { schoolId, schoolTermId, schoolStaffId } = school;
            const { studentGroupId } = studentGroup;

            const params = {
                url: {
                    schoolId,
                    schoolTermId,
                    schoolStaffId,
                    studentGroupId,
                },
                body: {
                    student: {
                        studentEnrollmentId,
                        generatedFrom: null,
                        deleted,
                    },
                },
            };

            network.studentGroups.saveGroupStudent(params, (err, res) => {
                v.saving = false;
                if (err) return v.showError(err);
                const groupStudents = deleted ? studentGroup.students.filter((s) => s.studentEnrollmentId != studentEnrollmentId)
                    : [...studentGroup.students, ...res.student];
                v.$store.commit(Types.mutations.SET_DB_STUDENT_GROUPS, [{ ...studentGroup, students: groupStudents }]);
            });
        },
        editGroup(group) {
            const v = this;
            const {
                schoolStaffId, studentGroup, user, canEditGroup,
            } = v;
            if (v.saving || !studentGroup || !canEditGroup) return;
            v.saving = true;

            const { school } = user;
            const { schoolId, schoolTermId } = school;
            const {
                studentGroupId, deleted, groupDescription, groupType, schoolTermMarkingPeriodId,
            } = group;

            const params = {
                url: { schoolId, schoolTermId, schoolStaffId },
                body: {
                    studentGroup: {
                        studentGroupId,
                        schoolTermMarkingPeriodId,
                        groupDescription,
                        groupType,
                        deleted,
                    },
                },
            };

            network.studentGroups.editGroup(params, (err, res) => {
                v.saving = false;
                if (err) return v.showError(err);
                const groupChanges = res.studentGroups[0];
                v.$store.commit(Types.mutations.SET_DB_STUDENT_GROUPS, [{ ...studentGroup, ...groupChanges }]);
            });
        },
        saveGroupInfo() {
            const v = this;
            const {
                schoolStaffId, settings, studentGroup, user, canEditGroup,
            } = v;
            if (v.saving || !studentGroup) return;
            v.saving = true;

            const { school } = user;
            const { schoolId, schoolTermId } = school;
            const {
                groupDescription, groupName, groupColor, groupIcon,
            } = settings;

            const {
                studentGroupId, deleted, groupType, schoolTermMarkingPeriodId,
            } = studentGroup;

            async.auto({
                description(next) {
                    if (!canEditGroup || groupDescription === studentGroup.groupDescription) return next();

                    const params = {
                        url: { schoolId, schoolTermId, schoolStaffId },
                        body: {
                            studentGroup: {
                                studentGroupId,
                                schoolTermMarkingPeriodId,
                                groupDescription,
                                groupType: groupType == 'manual' ? 'auto' : 'manual',
                                deleted,
                            },
                        },
                    };

                    network.studentGroups.editGroup(params, next);
                },
                settings(next) {
                    const params = {
                        url: { schoolId, schoolTermId, schoolStaffId },
                        body: {
                            staff: [{
                                studentGroupId,
                                schoolStaffId,
                                canEditGroup,
                                groupName: groupName || studentGroup.groupName,
                                groupIcon: groupIcon || null,
                                groupColor: groupColor || null,
                                groupIndex: null,
                                deleted: false,
                            }],
                        },
                    };

                    network.studentGroups.saveGroupStaff(params, next);
                },
            }, (err, results) => {
                v.saving = false;
                if (err) return v.showError(err);
                const description = results.description ? results.description.studentGroups[0].groupDescription : studentGroup.groupDescription;
                v.$store.commit(Types.mutations.SET_DB_STUDENT_GROUPS, [{
                    ...studentGroup, groupName: groupName || studentGroup.groupName, groupIcon, groupColor, groupDescription: description,
                }]);
            });
        },
        deleteGroup() {
            const v = this;
            const {
                schoolStaffId, studentGroup, user, canEditGroup,
            } = v;
            if (v.saving || !studentGroup || !canEditGroup) return;
            v.saving = true;

            const { school } = user;
            const { schoolId, schoolTermId } = school;
            const {
                studentGroupId, deleted, groupDescription, groupType, schoolTermMarkingPeriodId,
            } = studentGroup;

            const params = {
                url: { schoolId, schoolTermId, schoolStaffId },
                body: {
                    studentGroup: {
                        studentGroupId,
                        schoolTermMarkingPeriodId,
                        groupDescription,
                        groupType: groupType == 'manual' ? 'auto' : 'manual',
                        deleted,
                    },
                },
            };

            network.studentGroups.editGroup(params, (err, res) => {
                v.saving = false;
                if (err) return v.showError(err);
                const groupChanges = res.studentGroups[0];
                v.$store.commit(Types.mutations.SET_DB_STUDENT_GROUPS, [{ ...studentGroup, ...groupChanges }]);
            });
        },
    },
};
</script>

<style scoped>
.remove-staff {
    display: none !important;
}
.kt-widget4__pic:hover .remove-staff {
    display: flex !important;
}
.kt-widget4__pic:hover .staff-image {
    display: none !important;
}
.remove-member {
    display: none !important;
}
.kt-widget2__checkbox:hover .remove-member {
    display: flex !important;
}
.kt-widget2__checkbox:hover .member-image {
    display: none !important;
}
.kt-widget31__pic:hover .remove-member {
    display: flex !important;
}
.kt-widget31__pic:hover .member-image {
    display: none !important;
}
span.course-icon {
    font-size: 1.3rem;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}
</style>
