var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { key: `key_${_vm.key}` }, [
    _c("div", { staticClass: "kt-subheader kt-grid__item" }, [
      _c("div", { staticClass: "kt-container kt-container--fluid" }, [
        _c("div", { staticClass: "kt-subheader__main" }, [
          _c(
            "h3",
            { staticClass: "kt-subheader__title text-nowrap text-truncate" },
            [
              _c("SVGIcon", {
                staticClass: "mt-2 mr-3",
                attrs: { "hex-color": "#0f88ef", name: "group" },
              }),
              _vm._v(" Student Groups "),
            ],
            1
          ),
          _vm.schoolTermMarkingPeriodId
            ? _c(
                "ul",
                {
                  staticClass:
                    "nav nav-pills nav-pills-sm nav-sm py-0 px-3 my-0",
                  attrs: { role: "tablist" },
                },
                [
                  _c("small", { staticClass: "mx-3 mt-2" }, [
                    _vm._v("Group Working Marking Period"),
                  ]),
                  _vm._l(_vm.visibleMarkingPeriods, function (mp) {
                    return _c(
                      "li",
                      {
                        key: `mp_${mp.schoolTermMarkingPeriodId}`,
                        staticClass: "nav-item",
                      },
                      [
                        _c(
                          "a",
                          {
                            staticClass: "nav-link",
                            class: {
                              active:
                                mp.schoolTermMarkingPeriodId ==
                                _vm.schoolTermMarkingPeriodId,
                            },
                            attrs: { href: "#" },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                $event.preventDefault()
                                return _vm.editGroup({
                                  ..._vm.studentGroup,
                                  schoolTermMarkingPeriodId:
                                    mp.schoolTermMarkingPeriodId,
                                })
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(mp.markingPeriod) + " ")]
                        ),
                      ]
                    )
                  }),
                ],
                2
              )
            : _vm._e(),
        ]),
        _vm.formattedGroup
          ? _c("div", { staticClass: "kt-subheader__toolbar" }, [
              _c("div", { staticClass: "kt-subheader__wrapper mt-1" }, [
                !_vm.formattedGroup.deleted && _vm.isGroupOwner
                  ? _c(
                      "button",
                      {
                        staticClass: "btn btn-clean btn-clean-danger btn-bold",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            $event.preventDefault()
                            return _vm.editGroup({
                              ..._vm.studentGroup,
                              deleted: true,
                            })
                          },
                        },
                      },
                      [_vm._v(" Delete Group ")]
                    )
                  : _vm.formattedGroup.deleted && _vm.isGroupOwner
                  ? _c(
                      "button",
                      {
                        staticClass: "btn btn-clean btn-clean-success btn-bold",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            $event.preventDefault()
                            return _vm.editGroup({
                              ..._vm.studentGroup,
                              deleted: false,
                            })
                          },
                        },
                      },
                      [_vm._v(" Restore Group ")]
                    )
                  : _vm._e(),
                !_vm.formattedGroup.deleted && !_vm.isGroupOwner
                  ? _c(
                      "button",
                      {
                        staticClass: "btn btn-clean btn-clean-warning btn-bold",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            $event.preventDefault()
                            return _vm.staffChanged({
                              schoolStaffId: _vm.schoolStaffId,
                              canEditGroup: _vm.formattedGroup.canEditGroup,
                              deleted: true,
                            })
                          },
                        },
                      },
                      [_vm._v(" Leave Group ")]
                    )
                  : _vm._e(),
              ]),
            ])
          : _vm._e(),
      ]),
    ]),
    _c(
      "div",
      {
        staticClass:
          "kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid",
      },
      [
        !_vm.formattedGroup
          ? _c("div", [_vm._v(" No group found ")])
          : _c("div", [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-md-12 col-lg-7 col-xl-8" }, [
                  _c("div", { staticClass: "kt-portlet kt-portlet--mobile" }, [
                    _c("div", { staticClass: "kt-portlet__head" }, [
                      _c("div", { staticClass: "kt-portlet__head-label" }, [
                        _c("h3", { staticClass: "kt-portlet__head-title" }, [
                          _vm._v(" Group Info "),
                          _c("small", [
                            _vm._v(_vm._s(_vm.formattedGroup.groupName) + " "),
                          ]),
                        ]),
                      ]),
                      _c("div", { staticClass: "kt-portlet__head-toolbar" }, [
                        _c("div", { staticClass: "kt-portlet__head-actions" }, [
                          _c(
                            "button",
                            {
                              staticClass:
                                "btn btn-clean btn-clean-primary btn-sm btn-bold",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  $event.preventDefault()
                                  return _vm.saveGroupInfo.apply(
                                    null,
                                    arguments
                                  )
                                },
                              },
                            },
                            [_vm._v(" Save Info ")]
                          ),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "kt-portlet__body" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "row row-no-padding row-col-separator-xl",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "col-lg-8" },
                            [
                              _vm.canEditGroup
                                ? _c("v-md-editor", {
                                    staticClass: "v-md-editor-markdown",
                                    attrs: {
                                      height: "220px",
                                      "right-toolbar": _vm.rightToolbar,
                                      "left-toolbar": _vm.leftToolbar,
                                      mode: "edit",
                                    },
                                    model: {
                                      value: _vm.settings.groupDescription,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.settings,
                                          "groupDescription",
                                          $$v
                                        )
                                      },
                                      expression: "settings.groupDescription",
                                    },
                                  })
                                : _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.formattedGroup.groupDescription
                                        ) +
                                        " "
                                    ),
                                  ]),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "col-4 px-4" }, [
                            _c("div", [
                              _c("label", [_vm._v("Group Name")]),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.settings.groupName,
                                    expression: "settings.groupName",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  type: "text",
                                  maxlength: "255",
                                  placeholder: "",
                                },
                                domProps: { value: _vm.settings.groupName },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.settings,
                                      "groupName",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]),
                            _c("div", { staticClass: "mt-3" }, [
                              _c("label", { staticClass: "w-100" }, [
                                _vm._v("Group Color"),
                              ]),
                              _c(
                                "div",
                                { staticClass: "input-group" },
                                [
                                  _c("ColorSwatch", {
                                    staticClass: "input-group-prepend",
                                    attrs: {
                                      "color-change": _vm.selectedGroupColor,
                                      "color-selected":
                                        _vm.settings.groupColor || "White",
                                      "item-index": 0,
                                    },
                                  }),
                                  _c("input", {
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "text",
                                      disabled: "disabled",
                                    },
                                    domProps: {
                                      value: _vm.settings.groupColor,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _c("div", { staticClass: "mt-3" }, [
                              _c("label", { staticClass: "w-100" }, [
                                _vm._v("Group Icon"),
                              ]),
                              _c(
                                "div",
                                { staticClass: "input-group kt-input-icon" },
                                [
                                  _c("EmojiPicker", {
                                    staticClass: "input-group-prepend",
                                    attrs: {
                                      "initial-emoji": _vm.settings.groupIcon,
                                      "on-selected": _vm.emojiPicked,
                                    },
                                  }),
                                  _c("input", {
                                    staticClass: "form-control pr-5",
                                    attrs: {
                                      type: "text",
                                      readonly: "readonly",
                                    },
                                    domProps: { value: _vm.settings.groupIcon },
                                  }),
                                  _vm.settings.groupIcon
                                    ? _c(
                                        "span",
                                        {
                                          staticClass:
                                            "kt-input-icon__icon kt-input-icon__icon--right",
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              $event.preventDefault()
                                              return _vm.clearIcon.apply(
                                                null,
                                                arguments
                                              )
                                            },
                                          },
                                        },
                                        [_vm._m(0)]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "col-md-12 col-lg-5 col-xl-4" }, [
                  _c(
                    "div",
                    {
                      staticClass: "kt-portlet kt-portlet--skin-solid",
                      class: {
                        "kt-portlet--solid-success": _vm.groupType === "auto",
                        "kt-portlet--solid-primary": _vm.groupType === "manual",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "kt-portlet__head kt-portlet__head--noborder",
                        },
                        [
                          _c("div", { staticClass: "kt-portlet__head-label" }, [
                            _c(
                              "h3",
                              {
                                staticClass:
                                  "kt-portlet__head-title kt-font-bolder text-capitalize",
                              },
                              [_vm._v(" " + _vm._s(_vm.groupType) + " Mode ")]
                            ),
                          ]),
                          _vm.canEditGroup
                            ? _c(
                                "div",
                                { staticClass: "kt-portlet__head-toolbar" },
                                [
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn btn-outline-light btn-sm btn-hover-light btn-bold",
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          $event.preventDefault()
                                          return _vm.editGroup({
                                            ..._vm.studentGroup,
                                            groupType:
                                              _vm.groupType === "auto"
                                                ? "manual"
                                                : "auto",
                                          })
                                        },
                                      },
                                    },
                                    [_vm._v(" Switch Mode ")]
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "kt-portlet__body kt-margin-t-0 kt-padding-t-0",
                        },
                        [
                          _c("div", { staticClass: "kt-font-bolder" }, [
                            _vm.groupType === "manual"
                              ? _c("span", [
                                  _vm._v(
                                    " Students in a Manual group are only added or removed to this group when you manually refresh the group. "
                                  ),
                                ])
                              : _c("span", [
                                  _vm._v(
                                    " Students in an Auto group are automatically added and removed based on the rules you set. These group changes occur whenever specific student portfolios are changed. "
                                  ),
                                ]),
                          ]),
                        ]
                      ),
                    ]
                  ),
                  _c("div", { staticClass: "kt-portlet kt-portlet--mobile" }, [
                    _c("div", { staticClass: "kt-portlet__head" }, [
                      _vm._m(1),
                      _c("div", { staticClass: "kt-portlet__head-toolbar" }, [
                        _c("div", { staticClass: "kt-portlet__head-actions" }, [
                          !_vm.addingStaff
                            ? _c(
                                "button",
                                {
                                  staticClass:
                                    "btn btn-clean btn-sm btn-icon btn-icon-md",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      $event.preventDefault()
                                      _vm.addingStaff = !_vm.addingStaff
                                    },
                                  },
                                },
                                [_c("i", { staticClass: "fa fa-plus" })]
                              )
                            : _c(
                                "button",
                                {
                                  staticClass:
                                    "btn btn-clean btn-clean-danger btn-sm btn-bold",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      $event.preventDefault()
                                      _vm.addingStaff = !_vm.addingStaff
                                    },
                                  },
                                },
                                [_vm._v(" Cancel ")]
                              ),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "kt-portlet__body" }, [
                      _c(
                        "div",
                        { staticClass: "kt-widget4" },
                        [
                          _vm._l(
                            _vm.formattedGroup.staff,
                            function (staff, idx) {
                              return _c(
                                "div",
                                {
                                  key: `groupStaff_${staff.schoolStaffId}_${idx}`,
                                  staticClass: "kt-widget4__item",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "kt-widget4__pic kt-widget4__pic--pic",
                                    },
                                    [
                                      _vm.isGroupOwner &&
                                      _vm.schoolStaffId != staff.schoolStaffId
                                        ? _c(
                                            "span",
                                            {
                                              staticClass:
                                                "btn btn-clean btn-clean-danger btn-sm btn-icon btn-icon-md remove-staff",
                                              staticStyle: {
                                                "max-width": "40px",
                                                width: "40px",
                                                height: "40px",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  $event.preventDefault()
                                                  return _vm.staffChanged({
                                                    schoolStaffId:
                                                      staff.schoolStaffId,
                                                    canEditGroup:
                                                      staff.canEditGroup,
                                                    deleted: true,
                                                  })
                                                },
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "la la-close",
                                              }),
                                            ]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "UserAvatar",
                                        {
                                          class: {
                                            "staff-image":
                                              _vm.isGroupOwner &&
                                              _vm.schoolStaffId !=
                                                staff.schoolStaffId,
                                          },
                                          staticStyle: {
                                            "max-width": "40px",
                                            width: "40px",
                                            height: "40px",
                                          },
                                          attrs: {
                                            "avatar-user": staff.teacher,
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "kt-badge kt-badge--lg kt-badge--success",
                                              staticStyle: {
                                                "max-width": "40px",
                                                width: "40px",
                                                height: "40px",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    staff.teacher
                                                      ? `${staff.teacher.lastName.substring(
                                                          0,
                                                          2
                                                        )}`
                                                      : "-"
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "kt-widget4__info" },
                                    [
                                      staff.teacher
                                        ? _c(
                                            "router-link",
                                            {
                                              staticClass:
                                                "kt-widget4__username",
                                              attrs: {
                                                to: {
                                                  name: "TeacherInfo",
                                                  params: {
                                                    schoolEmail:
                                                      staff.teacher.schoolEmail,
                                                  },
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    `${staff.teacher.firstName} ${staff.teacher.lastName}`
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          )
                                        : _c(
                                            "a",
                                            {
                                              staticClass:
                                                "kt-widget4__username",
                                              attrs: { href: "#" },
                                            },
                                            [_vm._v(" Unknown Staff ")]
                                          ),
                                      _c(
                                        "p",
                                        { staticClass: "kt-widget4__text" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                staff.teacher
                                                  ? staff.teacher.jobTitle
                                                  : ""
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm.formattedGroup.schoolStaffId ==
                                  staff.schoolStaffId
                                    ? _c(
                                        "span",
                                        {
                                          staticClass:
                                            "btn btn-sm btn-label-success btn-bold",
                                        },
                                        [_vm._v(" Owner ")]
                                      )
                                    : _c("span", [
                                        _vm.isGroupOwner
                                          ? _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "btn btn-sm btn-bold",
                                                class: staff.canEditGroup
                                                  ? "btn-label-warning"
                                                  : "btn-label-primary",
                                                attrs: { type: "button" },
                                                on: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                    $event.preventDefault()
                                                    return _vm.staffChanged({
                                                      schoolStaffId:
                                                        staff.schoolStaffId,
                                                      canEditGroup:
                                                        !staff.canEditGroup,
                                                      deleted: false,
                                                    })
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      staff.canEditGroup
                                                        ? "Editor"
                                                        : "Viewer"
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          : _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "btn btn-sm btn-bold",
                                                class: staff.canEditGroup
                                                  ? "btn-label-warning"
                                                  : "btn-label-primary",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      staff.canEditGroup
                                                        ? "Editor"
                                                        : "Viewer"
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                      ]),
                                ]
                              )
                            }
                          ),
                          _vm.addingStaff
                            ? _c("div", { staticClass: "kt-widget4__item" }, [
                                _c(
                                  "div",
                                  { staticClass: "kt-widget4__info" },
                                  [
                                    _c("SearchRecipients", {
                                      attrs: {
                                        "on-selected": _vm.staffSelected,
                                        "school-staff-id": _vm.schoolStaffId,
                                        "allow-school-wide": false,
                                        "allow-my-students": false,
                                        "show-courses": false,
                                        "show-home-rooms": false,
                                        "show-grade-levels": false,
                                        "show-student-groups": false,
                                        "show-students": false,
                                        "show-guardians": false,
                                        placeholder: "Search for staff",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ])
                            : _vm._e(),
                        ],
                        2
                      ),
                    ]),
                  ]),
                ]),
              ]),
              _c("div", [
                _c("div", { staticClass: "kt-portlet kt-portlet--mobile" }, [
                  _c("div", { staticClass: "kt-portlet__head" }, [
                    _vm._m(2),
                    _c("div", { staticClass: "kt-portlet__head-toolbar" }, [
                      _c("div", { staticClass: "kt-portlet__head-actions" }, [
                        _vm.editMode
                          ? _c(
                              "button",
                              {
                                staticClass:
                                  "btn btn-warning btn-sm btn-bold mr-2",
                                class: {
                                  "kt-spinner kt-spinner--sm": _vm.saving,
                                },
                                attrs: { type: "button", disabled: _vm.saving },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    $event.preventDefault()
                                    return _vm.saveRules.apply(null, arguments)
                                  },
                                },
                              },
                              [_vm._v(" Save Rules ")]
                            )
                          : _vm._e(),
                        _vm.formattedGroup.groupRules.length
                          ? _c(
                              "button",
                              {
                                staticClass:
                                  "btn btn-success btn-sm btn-bold mr-2",
                                class: {
                                  "kt-spinner kt-spinner--sm kt-spinner--light":
                                    _vm.saving,
                                },
                                attrs: { type: "button", disabled: _vm.saving },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    $event.preventDefault()
                                    return _vm.refreshGroupStudents.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              },
                              [_vm._v(" Refresh Students ")]
                            )
                          : _vm._e(),
                        _vm.canEditGroup
                          ? _c(
                              "button",
                              {
                                staticClass: "btn btn-sm btn-bold",
                                class: _vm.editMode
                                  ? "btn-primary"
                                  : "btn-clean btn-clean-primary",
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    $event.preventDefault()
                                    _vm.editMode = !_vm.editMode
                                  },
                                },
                              },
                              [_vm._v(" Edit Students ")]
                            )
                          : _vm._e(),
                      ]),
                    ]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "kt-portlet__body" },
                    [
                      _c(
                        "b-collapse",
                        {
                          model: {
                            value: _vm.editMode,
                            callback: function ($$v) {
                              _vm.editMode = $$v
                            },
                            expression: "editMode",
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "col-4 mb-5" },
                            [
                              _c("SearchRecipients", {
                                attrs: {
                                  "on-selected": _vm.addMember,
                                  "school-staff-id": _vm.schoolStaffId,
                                  "allow-school-wide": false,
                                  "allow-my-students": false,
                                  "show-courses": true,
                                  "show-home-rooms": true,
                                  "show-grade-levels": true,
                                  "show-student-groups": false,
                                  "show-students": true,
                                  "show-guardians": false,
                                  "show-teachers": false,
                                  placeholder:
                                    "Individual students, courses, homeroom, etc.",
                                },
                              }),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "ml-2 mb-3" }, [
                            _vm.groupRules.groupings.length ||
                            _vm.groupRules.students.length
                              ? _c("small", [_vm._v("Group Rules")])
                              : _vm._e(),
                          ]),
                          _c(
                            "div",
                            { staticClass: "kt-widget2" },
                            [
                              _vm._l(
                                _vm.groupRules.groupings,
                                function (grouping, idx) {
                                  return _c(
                                    "div",
                                    {
                                      key: `new_grouping_${idx}`,
                                      staticClass: "kt-widget2__item",
                                      class: {
                                        "kt-widget2__item--success":
                                          grouping.group.courseSectionId,
                                        "kt-widget2__item--primary":
                                          grouping.group.homeRoom,
                                        "kt-widget2__item--warning":
                                          grouping.group.gradeLevel,
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "kt-widget2__checkbox" },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "btn btn-clean btn-clean-danger btn-sm btn-icon btn-icon-md remove-member mb-3",
                                              staticStyle: {
                                                "max-width": "35px",
                                                width: "35px",
                                                height: "35px",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  $event.preventDefault()
                                                  return _vm.removeMember(
                                                    "grouping",
                                                    idx
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "la la-close",
                                              }),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "kt-badge kt-badge--lg kt-badge--success member-image mb-3",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    `${
                                                      grouping.group
                                                        ? grouping.group.name
                                                        : "-"
                                                    }`.substring(0, 2)
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "kt-widget2__info mt-2",
                                          staticStyle: {
                                            width: "27%",
                                            "max-width": "27%",
                                          },
                                        },
                                        [
                                          _c(
                                            "a",
                                            {
                                              staticClass:
                                                "kt-widget2__title kt-font-lg",
                                              attrs: { href: "#" },
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  $event.preventDefault()
                                                  return _vm.removeMember(
                                                    "grouping",
                                                    idx
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    grouping.group
                                                      ? grouping.group.name
                                                      : "Unknown Grouping"
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "kt-widget2__username",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    grouping.group
                                                      ? grouping.group
                                                          .extCourseSectionId ||
                                                          grouping.group
                                                            .subName ||
                                                          ""
                                                      : ""
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "kt-widget2__actions",
                                          staticStyle: {
                                            width: "65%",
                                            "max-width": "65%",
                                            "text-align": "left",
                                          },
                                        },
                                        [
                                          _vm._l(
                                            grouping.filters,
                                            function (filter, index) {
                                              return _c(
                                                "div",
                                                {
                                                  key: `new_grouping_${idx}_filter_${index}`,
                                                  staticClass: "pb-2",
                                                },
                                                [
                                                  _c(
                                                    "button",
                                                    {
                                                      staticClass:
                                                        "btn btn-clean btn-clean-danger btn-icon btn-sm",
                                                      attrs: { type: "button" },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                          $event.preventDefault()
                                                          return _vm.removeGroupFilter(
                                                            idx,
                                                            index
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "la la-close",
                                                      }),
                                                    ]
                                                  ),
                                                  filter.filter ===
                                                  "coursesWithAverage"
                                                    ? _c(
                                                        "span",
                                                        { staticClass: "pl-2" },
                                                        [
                                                          _c("input", {
                                                            directives: [
                                                              {
                                                                name: "model",
                                                                rawName:
                                                                  "v-model",
                                                                value:
                                                                  filter.filterValue,
                                                                expression:
                                                                  "filter.filterValue",
                                                              },
                                                            ],
                                                            staticClass:
                                                              "form-control col-2",
                                                            staticStyle: {
                                                              display: "inline",
                                                            },
                                                            attrs: {
                                                              type: "number",
                                                            },
                                                            domProps: {
                                                              value:
                                                                filter.filterValue,
                                                            },
                                                            on: {
                                                              input: function (
                                                                $event
                                                              ) {
                                                                if (
                                                                  $event.target
                                                                    .composing
                                                                )
                                                                  return
                                                                _vm.$set(
                                                                  filter,
                                                                  "filterValue",
                                                                  $event.target
                                                                    .value
                                                                )
                                                              },
                                                            },
                                                          }),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass: "pl-2 pr-2",
                                                    },
                                                    [
                                                      _c(
                                                        "select",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value:
                                                                filter.filter,
                                                              expression:
                                                                "filter.filter",
                                                            },
                                                          ],
                                                          staticClass:
                                                            "form-control",
                                                          class: {
                                                            "col-5":
                                                              filter.filter !==
                                                              "coursesWithAverage",
                                                            "col-3":
                                                              filter.filter ===
                                                              "coursesWithAverage",
                                                          },
                                                          staticStyle: {
                                                            display: "inline",
                                                          },
                                                          on: {
                                                            change: function (
                                                              $event
                                                            ) {
                                                              var $$selectedVal =
                                                                Array.prototype.filter
                                                                  .call(
                                                                    $event
                                                                      .target
                                                                      .options,
                                                                    function (
                                                                      o
                                                                    ) {
                                                                      return o.selected
                                                                    }
                                                                  )
                                                                  .map(
                                                                    function (
                                                                      o
                                                                    ) {
                                                                      var val =
                                                                        "_value" in
                                                                        o
                                                                          ? o._value
                                                                          : o.value
                                                                      return val
                                                                    }
                                                                  )
                                                              _vm.$set(
                                                                filter,
                                                                "filter",
                                                                $event.target
                                                                  .multiple
                                                                  ? $$selectedVal
                                                                  : $$selectedVal[0]
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          grouping.group
                                                            .courseSectionId
                                                            ? _c(
                                                                "option",
                                                                {
                                                                  attrs: {
                                                                    value:
                                                                      "average",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Average"
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                          _c(
                                                            "option",
                                                            {
                                                              attrs: {
                                                                value:
                                                                  "overallAverage",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "Overall Average"
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "option",
                                                            {
                                                              attrs: {
                                                                value:
                                                                  "attendance",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "Attendance YTD"
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "option",
                                                            {
                                                              attrs: {
                                                                value:
                                                                  "reportCardGrade",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "Report Card Grade"
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "option",
                                                            {
                                                              attrs: {
                                                                value:
                                                                  "reportCardAverage",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "Overall Report Card Average"
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "option",
                                                            {
                                                              attrs: {
                                                                value:
                                                                  "coursesWithAverage",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "Courses with average"
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass: "pl-2 pr-2",
                                                    },
                                                    [
                                                      _c(
                                                        "select",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value:
                                                                filter.relation,
                                                              expression:
                                                                "filter.relation",
                                                            },
                                                          ],
                                                          staticClass:
                                                            "form-control col-2",
                                                          staticStyle: {
                                                            display: "inline",
                                                          },
                                                          on: {
                                                            change: function (
                                                              $event
                                                            ) {
                                                              var $$selectedVal =
                                                                Array.prototype.filter
                                                                  .call(
                                                                    $event
                                                                      .target
                                                                      .options,
                                                                    function (
                                                                      o
                                                                    ) {
                                                                      return o.selected
                                                                    }
                                                                  )
                                                                  .map(
                                                                    function (
                                                                      o
                                                                    ) {
                                                                      var val =
                                                                        "_value" in
                                                                        o
                                                                          ? o._value
                                                                          : o.value
                                                                      return val
                                                                    }
                                                                  )
                                                              _vm.$set(
                                                                filter,
                                                                "relation",
                                                                $event.target
                                                                  .multiple
                                                                  ? $$selectedVal
                                                                  : $$selectedVal[0]
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c("option", [
                                                            _vm._v("="),
                                                          ]),
                                                          _c("option", [
                                                            _vm._v(_vm._s(">")),
                                                          ]),
                                                          _c("option", [
                                                            _vm._v(
                                                              _vm._s(">=")
                                                            ),
                                                          ]),
                                                          _c("option", [
                                                            _vm._v(_vm._s("<")),
                                                          ]),
                                                          _c("option", [
                                                            _vm._v(
                                                              _vm._s("<=")
                                                            ),
                                                          ]),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "span",
                                                    { staticClass: "pl-2" },
                                                    [
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              filter.relationValue,
                                                            expression:
                                                              "filter.relationValue",
                                                          },
                                                        ],
                                                        staticClass:
                                                          "form-control col-2",
                                                        staticStyle: {
                                                          display: "inline",
                                                        },
                                                        attrs: {
                                                          type: "number",
                                                        },
                                                        domProps: {
                                                          value:
                                                            filter.relationValue,
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            if (
                                                              $event.target
                                                                .composing
                                                            )
                                                              return
                                                            _vm.$set(
                                                              filter,
                                                              "relationValue",
                                                              $event.target
                                                                .value
                                                            )
                                                          },
                                                        },
                                                      }),
                                                      _vm._v(" % "),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          _c("div", [
                                            _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "btn btn-label-primary btn-icon btn-sm",
                                                attrs: { type: "button" },
                                                on: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                    $event.preventDefault()
                                                    return _vm.addGroupFilter(
                                                      idx
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("i", {
                                                  staticClass: "la la-plus",
                                                }),
                                              ]
                                            ),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "kt-font-md kt-font-bold ml-2",
                                              },
                                              [_vm._v(" Add Student Filter ")]
                                            ),
                                          ]),
                                        ],
                                        2
                                      ),
                                    ]
                                  )
                                }
                              ),
                              _vm._l(
                                _vm.groupRules.students,
                                function (student, idx) {
                                  return _c(
                                    "div",
                                    {
                                      key: `new_student_${idx}`,
                                      staticClass:
                                        "kt-widget2__item kt-widget2__item--warning",
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "kt-widget2__checkbox" },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "btn btn-clean btn-clean-danger btn-sm btn-icon btn-icon-md remove-member mb-3",
                                              staticStyle: {
                                                "max-width": "35px",
                                                width: "35px",
                                                height: "35px",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  $event.preventDefault()
                                                  return _vm.removeMember(
                                                    "student",
                                                    idx
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "la la-close",
                                              }),
                                            ]
                                          ),
                                          _c(
                                            "UserAvatar",
                                            {
                                              staticClass: "member-image mb-3",
                                              staticStyle: {
                                                "max-width": "35px",
                                                width: "35px",
                                                height: "35px",
                                              },
                                              attrs: {
                                                "avatar-user": student.student,
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "kt-badge kt-badge--lg kt-badge--success",
                                                  staticStyle: {
                                                    "max-width": "35px",
                                                    width: "35px",
                                                    height: "35px",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        `${
                                                          student.student
                                                            ? student.student
                                                                .name
                                                            : "-"
                                                        }`.substring(0, 2)
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "kt-widget2__info mt-2",
                                          staticStyle: {
                                            width: "27%",
                                            "max-width": "27%",
                                          },
                                        },
                                        [
                                          _c(
                                            "a",
                                            {
                                              staticClass:
                                                "kt-widget2__title kt-font-lg",
                                              attrs: { href: "#" },
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  $event.preventDefault()
                                                  return _vm.removeMember(
                                                    "student",
                                                    idx
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    student.student
                                                      ? student.student.name
                                                      : "Unknown student"
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "kt-widget2__username",
                                            },
                                            [_vm._v(" Student ")]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "kt-widget2__actions",
                                          staticStyle: {
                                            width: "65%",
                                            "max-width": "65%",
                                            "text-align": "left",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "kt-checkbox-list" },
                                            [
                                              _c(
                                                "label",
                                                {
                                                  staticClass:
                                                    "kt-checkbox kt-checkbox--tick kt-checkbox--success",
                                                },
                                                [
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value: student.isRule,
                                                        expression:
                                                          "student.isRule",
                                                      },
                                                    ],
                                                    attrs: { type: "checkbox" },
                                                    domProps: {
                                                      checked: Array.isArray(
                                                        student.isRule
                                                      )
                                                        ? _vm._i(
                                                            student.isRule,
                                                            null
                                                          ) > -1
                                                        : student.isRule,
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        var $$a =
                                                            student.isRule,
                                                          $$el = $event.target,
                                                          $$c = $$el.checked
                                                            ? true
                                                            : false
                                                        if (
                                                          Array.isArray($$a)
                                                        ) {
                                                          var $$v = null,
                                                            $$i = _vm._i(
                                                              $$a,
                                                              $$v
                                                            )
                                                          if ($$el.checked) {
                                                            $$i < 0 &&
                                                              _vm.$set(
                                                                student,
                                                                "isRule",
                                                                $$a.concat([
                                                                  $$v,
                                                                ])
                                                              )
                                                          } else {
                                                            $$i > -1 &&
                                                              _vm.$set(
                                                                student,
                                                                "isRule",
                                                                $$a
                                                                  .slice(0, $$i)
                                                                  .concat(
                                                                    $$a.slice(
                                                                      $$i + 1
                                                                    )
                                                                  )
                                                              )
                                                          }
                                                        } else {
                                                          _vm.$set(
                                                            student,
                                                            "isRule",
                                                            $$c
                                                          )
                                                        }
                                                      },
                                                    },
                                                  }),
                                                  _vm._v(
                                                    " Save as a rule to always add this individual student when refreshing group "
                                                  ),
                                                  _c("span"),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                }
                              ),
                            ],
                            2
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        { style: `margin-top: ${_vm.editMode ? "8%" : ""}` },
                        [
                          _c(
                            "div",
                            { staticClass: "kt-widget31" },
                            _vm._l(
                              _vm.formattedGroup.students,
                              function (student, idx) {
                                return _c(
                                  "div",
                                  {
                                    key: `groupStudent_${student.studentEnrollmentId}_${idx}`,
                                    staticClass: "kt-widget31__item",
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "kt-widget31__content" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "kt-widget31__pic" },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "btn btn-clean btn-clean-danger btn-sm btn-icon btn-icon-md remove-member",
                                                staticStyle: {
                                                  "max-width": "40px",
                                                  width: "40px",
                                                  height: "40px",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                    $event.preventDefault()
                                                    return _vm.studentChanged(
                                                      student.studentEnrollmentId,
                                                      true
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("i", {
                                                  staticClass: "la la-close",
                                                }),
                                              ]
                                            ),
                                            _c(
                                              "UserAvatar",
                                              {
                                                staticClass: "member-image",
                                                staticStyle: {
                                                  "max-width": "40px",
                                                  width: "40px",
                                                  height: "40px",
                                                },
                                                attrs: {
                                                  "avatar-user":
                                                    student.student,
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "kt-badge kt-badge--lg kt-badge--success",
                                                    staticStyle: {
                                                      "max-width": "40px",
                                                      width: "40px",
                                                      height: "40px",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          `${
                                                            student.student
                                                              ? student.student
                                                                  .name
                                                              : "-"
                                                          }`.substring(0, 2)
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "kt-widget31__info" },
                                          [
                                            student.student
                                              ? _c(
                                                  "router-link",
                                                  {
                                                    staticClass:
                                                      "kt-widget31__username",
                                                    attrs: {
                                                      to: {
                                                        name: "StudentInfo",
                                                        params: {
                                                          studentEnrollmentId:
                                                            student.studentEnrollmentId,
                                                        },
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          `${student.student.firstName} ${student.student.lastName}`
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                )
                                              : _c(
                                                  "a",
                                                  {
                                                    staticClass:
                                                      "kt-widget31__username",
                                                    attrs: { href: "#" },
                                                  },
                                                  [_vm._v(" Unknown Student ")]
                                                ),
                                            _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "kt-widget31__text",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      student.student
                                                        ? `${
                                                            student.student
                                                              .homeRoom
                                                              ? `HR: ${student.student.homeRoom}`
                                                              : ""
                                                          } ${
                                                            student.student
                                                              .gradeLevel
                                                              ? `GL: ${student.student.gradeLevel}`
                                                              : ""
                                                          }`
                                                        : ""
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "kt-widget31__content",
                                        staticStyle: { width: "70%" },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "kt-widget31__progress",
                                          },
                                          [
                                            student.aggregates
                                              ? _c(
                                                  "div",
                                                  { staticClass: "row" },
                                                  [
                                                    _c(
                                                      "small",
                                                      {
                                                        staticClass:
                                                          "align-self-center mr-3",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "Report Card Average"
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._l(
                                                      [
                                                        (
                                                          student.aggregates
                                                            .reportCardAverages ||
                                                          []
                                                        ).find(
                                                          (a) =>
                                                            a.schoolTermMarkingPeriodId ==
                                                            _vm.schoolTermMarkingPeriodId
                                                        ) || {},
                                                      ],
                                                      function (avg, index) {
                                                        return _c(
                                                          "div",
                                                          {
                                                            key: `reportCardAverage_${avg.studentEnrollmentId}_${avg.schoolTermMarkingPeriodId}_${index}`,
                                                          },
                                                          [
                                                            avg.reportCardAverage
                                                              ? _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "btn btn-md btn-icon btn-primary mr-1",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "course-icon align-items-center d-flex",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              Math.round(
                                                                                avg.reportCardAverage
                                                                              )
                                                                            ) +
                                                                            " "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                )
                                                              : _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "btn btn-md btn-icon btn-primary mr-1",
                                                                    attrs: {
                                                                      disabled:
                                                                        avg.schoolTermMarkingPeriodId !=
                                                                        _vm.schoolTermMarkingPeriodId,
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "course-icon align-items-center d-flex",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " - "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    _c(
                                                      "small",
                                                      {
                                                        staticClass:
                                                          "align-self-center mr-3 ml-4",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "Overall Averages"
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._l(
                                                      [
                                                        ...(student.aggregates.progressAverages.filter(
                                                          (a) =>
                                                            a.schoolTermId ==
                                                            _vm.schoolTermId
                                                        ) || []),
                                                      ],
                                                      function (avg, index) {
                                                        return _c(
                                                          "div",
                                                          {
                                                            key: `progressAverage_${avg.studentEnrollmentId}_${avg.schoolTermMarkingPeriodId}_${index}`,
                                                          },
                                                          [
                                                            avg.progressAverage
                                                              ? _c(
                                                                  "button",
                                                                  {
                                                                    staticClass:
                                                                      "btn btn-md btn-icon btn-primary mr-1",
                                                                    attrs: {
                                                                      disabled:
                                                                        avg.schoolTermMarkingPeriodId !=
                                                                        _vm.schoolTermMarkingPeriodId,
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "course-icon align-items-center d-flex",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              Math.round(
                                                                                avg.progressAverage *
                                                                                  100
                                                                              )
                                                                            ) +
                                                                            " "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                )
                                                              : _c(
                                                                  "button",
                                                                  {
                                                                    staticClass:
                                                                      "btn btn-md btn-icon btn-primary mr-1",
                                                                    attrs: {
                                                                      disabled:
                                                                        avg.schoolTermMarkingPeriodId !=
                                                                        _vm.schoolTermMarkingPeriodId,
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "course-icon align-items-center d-flex",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " - "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                  ],
                                                  2
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "btn-label-brand btn btn-sm btn-bold",
                                            class: {
                                              Blue:
                                                student.generatedFrom ===
                                                "Individual",
                                              Orange:
                                                student.generatedFrom !==
                                                "Individual",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(student.generatedFrom)
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              }
                            ),
                            0
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
            ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [_c("i", { staticClass: "la la-close" })])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "kt-portlet__head-label" }, [
      _c("h3", { staticClass: "kt-portlet__head-title" }, [
        _vm._v(" Group Staff "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "kt-portlet__head-label" }, [
      _c("h3", { staticClass: "kt-portlet__head-title" }, [
        _vm._v(" Group Students "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }